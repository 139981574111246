import React from 'react';

import { 
    IonModal,
    IonHeader,
    IonButton,
} from '@ionic/react';

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
import Youtube from 'react-youtube-embed';

import styled from 'styled-components';

interface TrackModalProps {
    open: boolean
    close: Function
    trackData: any
}

const StyledIonModal = styled(IonModal)`
    color: black;
    --height: 80%;
`;


const TrackModal: React.FC<TrackModalProps> = props => {

    const {
        open,
        close,
        trackData
    } = props;
    const chartOptions = {
        series: [{
            type: 'line',
            name: "Popularity",
            data: trackData?.track_data?.map(t => parseInt(t.play_count))
        }],
        yAxis: {
            title: {
                text: 'Play Count'
            }
        },
    
        xAxis: {
            title: {
                text: 'Time'
            }
        },
        title: {
            text: trackData?.track?.track_name
        }
    };
    return <StyledIonModal 
            isOpen={open} 
            animated={true} 
            swipeToClose={true} 
            showBackdrop={true} 
            onDidDismiss={() => close()}
        >
        <IonHeader>{trackData?.track?.artist_name} - {trackData?.track?.track_name}</IonHeader>
        {!trackData?.track?.youtube_id || <Youtube id={trackData?.track?.youtube_id}/>}
        <HighchartsReact
            options = {chartOptions}
            highcharts = { Highcharts }
            allowChartUpdate = { true }
            immutable = { false }
        />

        <IonButton slot="end" onClick={() => close()}>CLOSE</IonButton>
    </StyledIonModal>;
};


export default TrackModal;