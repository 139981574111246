import React from 'react';
import { createStore, applyMiddleware, compose,combineReducers  } from 'redux';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import { createBrowserHistory } from 'history'
import { ConnectedRouter, connectRouter, routerMiddleware } from 'connected-react-router'
import {
  IonApp,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { createReducer } from 'typesafe-actions';

import store from './store';
import LoginContainer from './containers/Login';
import SignupContainer from './containers/Signup';
import HomeContainer from './containers/Home';
import HistoryContainer from './containers/History';
import CreateContestContainer from './containers/CreateContest';
import AttendContainer from './containers/Attend';
import ContestSearchContainer from './containers/ContestSearch';
import ContestContainer from './containers/ContestContainer';
import AdminContainer from './containers/AdminContainer';
import WalletContainer from './containers/WalletContainer';

import Menu from './components/Menu';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
const history = createBrowserHistory();
const App: React.FC = () => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <IonApp mode="md"  id="main-content">
          <Menu history={history}/>
          <IonReactRouter>
            <Route path="/(|main-stage)" component={HomeContainer} exact={true}/>
            <Route path="/Login" component={LoginContainer} />
            <Route path="/Signup" component={SignupContainer} />
            <Route path="/history" component={HistoryContainer}  exact={true} />
            <Route path="/create-contest" component={CreateContestContainer} exact={true} />
            <Route path="/attend" component={AttendContainer} />
            <Route path="/contest-search" component={ContestSearchContainer} />
            <Route path="/contest/:contestId" component={ContestContainer} />
            <Route path="/wallet" component={WalletContainer} />
            <Route path="/admin" component={AdminContainer} />
            <Route path="/logout" component={LoginContainer} />
          </IonReactRouter>
        </IonApp>
        </ConnectedRouter>
    </Provider>)
};

export const AppContext = store;

export default App;


