import React from 'react';
import {
    IonGrid,
    IonRow,
    IonCol,
    IonTitle,
    IonIcon,
    IonAvatar,
    IonItem,
    IonCardContent,
} from '@ionic/react';
import {
    discSharp,
    headset,
    time,
} from 'ionicons/icons';
import styled from 'styled-components';
import { ContestType } from './../../actions/apiTypes';

import ContestCountdown from './ContestCountdown';

const StyledIonTitle = styled(IonTitle)`
    margin-top: 5%;
    font-size: 2.3em;
    color: #0f2070;
    font-family: 'CentrailOne-Regular';
    font-weight: bold;
    font-style: italic;
`;

const StyledSubTxt = styled.p`
    font-size: .8em;
    color: #0f2070;
    font-family: 'Domaine-Regular';
`;

const StyledUserTxt = styled.p`
    font-size: 1.8em;
    color: #0f2070;
    font-family: 'Domaine-Regular';
    font-weight: bold;
    margin: 0;
`;

const StyledGuestTxt = styled(StyledUserTxt)`
    font-size: 1.1em;
`;

const StyledIonRow = styled(IonRow)`
    margin-top: 5px;
    margin-bottom: 5px;
`;

const BorderedIonCol = styled(IonCol)`
    border-bottom: 2px solid #0f2070;
`;

const StyledWindow = styled(IonItem)`
    height: calc(100vh - 235px);
    overflow: scroll;
    -webkit-overflow-scrolling: auto;
    width: 100%;
`;
const StyledIonGrid = styled(IonGrid)`
    width: 100%;
`;

const StyledIonIcon = styled(IonIcon)`
 
    width: 50px;
    height: 50px;
`;

const StyledTitleCol = styled(IonCol)`
    font-family: 'Domaine-Regular';
    text-align: center;
    font-size: 1.6em;
    color: #0f2070;
    font-weight: bold;
    margin-bottom: -30px;
    margin-top: 30px;
`;




interface ContestScreenProps {
    contest: ContestType["contestData"]
}

const ContestScreen: React.FC<ContestScreenProps> = props => {

    return (<StyledWindow lines="none"><StyledIonGrid>
            <StyledIonRow>
                <IonCol size="12" style={{textAlign: 'center'}}>
                    <StyledIonTitle>{props.contest.name}</StyledIonTitle>
                </IonCol>
            </StyledIonRow>
            <StyledIonRow>
                <IonCol size="12" style={{textAlign: 'center'}}>
                    <StyledSubTxt>Presented By</StyledSubTxt>
                </IonCol>
            </StyledIonRow>
            <StyledIonRow>
                <IonCol size="12" style={{textAlign: 'center'}}>
                    <StyledUserTxt>{`${props.contest.owner.firstName} ${props.contest.owner.lastName}`} </StyledUserTxt>
                </IonCol>
            </StyledIonRow>
            <StyledIonRow>
                <IonCol size="12" style={{textAlign: 'center'}}>
                    <StyledSubTxt>Confirmed Guests</StyledSubTxt>
                </IonCol>
            </StyledIonRow>
            <IonRow>
                <BorderedIonCol size="8" offset="2" style={{textAlign: 'center'}}>
                    <StyledGuestTxt>{props.contest.ranking.filter(u => u.id !== props.contest.owner.id).map(u => {
                        return `${u.firstName} ${u.lastName}`;
                    }).join(' / ')}</StyledGuestTxt>
                </BorderedIonCol>
            </IonRow>

            
            <IonRow style={{marginTop: '3%'}}>
                <IonCol size="4" offset="2" style={{textAlign: 'center'}}>
                    <StyledIonIcon icon={headset}  /><br />
                     {props.contest.pickCount} Picks
                </IonCol>
                <IonCol size="3" offset="1" style={{textAlign: 'center'}}>
                    <StyledIonIcon icon={time}  /><br />
                    {props.contest.duration} Weeks
                </IonCol>
            </IonRow>
            <IonRow>
                <StyledTitleCol size="12">
                    Show Begins
                </StyledTitleCol>
            </IonRow>
            <ContestCountdown endDate={props.contest.start}/>
        

        </StyledIonGrid></StyledWindow>);

};



export default ContestScreen;