import React, { useState } from 'react';

import {
    IonItem,
    IonSearchbar
} from '@ionic/react';
import { debounce } from 'lodash';
import SearchResults from './SearchResults';

interface SearchComponentProps {
    type?: string
    search: Function
    add: Function
    results: any
    showAdd: Boolean
    addLabel?: string
};

const SearchComponent: React.FC<SearchComponentProps> = props => {

    const [searchVal, setSearchVal] = useState("")

    return <><IonItem>
        <IonSearchbar
            id="search-bar"
            onIonChange={debounce(e => {
                props.search(e.detail.value!);
            }, 300)}
        />
    </IonItem>
    <SearchResults 
        type={props.type}
        results={props.results}
        add={props.add}
        addLabel={props.addLabel}
        setSearchVal={() => {
            props.search("");
            //@ts-ignore
            document.querySelector("#search-bar").querySelector('.searchbar-input').value = ""
        }}
        showAdd={props.showAdd}
        
    />
    </>
};


export default SearchComponent;