import React, { useEffect } from 'react';
import styled from 'styled-components';
import { 
    IonItem,
    IonList
} from '@ionic/react';

import SearchResultItem from './SearchResultItem';


const SearchResults = props  => {

  useEffect(() => {
  })
  // const results = [
  //   {id: 1, name: 'Pink Floyd - Comfortably Numb', art: 'https://is4-ssl.mzstatic.com/image/thumb/Music7/v4/e5/9f/fb/e59ffb3b-1283-ee80-56a5-1b983df53ab2/source/1200x1200bb.jpg'},
  // ];

  return (
  <IonList>
      {props.results.map(r => <SearchResultItem 
        key={r.trackId} 
        item={r} 
        add={props.add} 
        showAdd={props.showAdd}
        setSearchVal={props.setSearchVal}
        addLabel={props.addLabel}
      />)}
  </IonList>)
};

export default SearchResults;
