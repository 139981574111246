import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { 
    IonContent,
    IonLoading
 } from '@ionic/react';

import Header from './../Header';
import BottomNavTabs from '../BottomNavTabs';
import ContestSegmentTabs from './ContestSegmentTabs';

import Leaderboard from './Leaderboard';
import ContestScreen from './ContestScreen';
import JoinScreen from './JoinScreen';
import CollectionScreen from './CollectionScreen';
import PlaylistPage from './PlaylistPage';
import UserPicks from './UserPicks';
import ContestHeader from './ContestHeader';
import { ContestType } from './../../actions/apiTypes';

interface ContestPageProps {
    contest: ContestType["contestData"]
    page: string
    changePage: Function,
    joinContest: Function,
    joinContestResults: {
        error: Boolean,
        errorMessage: string
    },
    searchMusic: Function,
    addPickToCollection: Function,
    removePickFromCollection: Function,
    musicSearchResults: any,
    userData: any
    trackData: any
    getTrackData: Function
    clearTrackData: Function
    getPickData: Function
    pickData: any
    contestId: string
    userId: string
    contestTracks: any
    getContestTracks: Function
    removeFromContest: Function
}

const getActualPage = (page, props) => {
    let actualPage = page;
    if ((props.started && page == "2") || (page === "2" && props.contest.joined)) actualPage = "1";
    if (props.started && page == "0") actualPage = "1";
    if (props.started && props.userId && props.contestId) actualPage = "4";
    return actualPage;
}

const getRenderedPage = (page, props) => {
    const actualPage = getActualPage(page, props);
    switch(actualPage) {
        case "0": return <ContestScreen contest={props.contest} />;
        case "1": return <Leaderboard 
            contest={props.contest}
            userData={props.userData}
            joinContest={props.joinContest}
            removeFromContest={props.removeFromContest}
        />;
        case "2": return <JoinScreen 
            contest={props.contest} 
            joinContest={props.joinContest}
            joinContestResults={props.joinContestResults}  
            userData={props.userData}
        />
        case "3": return <CollectionScreen 
                searchMusic={props.searchMusic}
                musicSearchResults={props.musicSearchResults}
                addPickToCollection={props.addPickToCollection}
                removePickFromCollection={props.removePickFromCollection}
                contest={props.contest}
                trackData={props.trackData}
                getTrackData={props.getTrackData}
                clearTrackData={props.clearTrackData}
                userData={props.userData}
            />;
        case "4": return <UserPicks 
                contest={props.contest}
                userId={props.userId}
                contestId={props.contestId}
                getPickData={props.getPickData}
                pickData={props.pickData}
                trackData={props.trackData}
                getTrackData={props.getTrackData}
                clearTrackData={props.clearTrackData}
            />;
        case "6": return <PlaylistPage 
            contestId={props.contestId}
            contestTracks={props.contestTracks}
            getContestTracks={props.getContestTracks}
            trackData={props.trackData}
            getTrackData={props.getTrackData}
            contest={props.contest}
            clearTrackData={props.clearTrackData}
            />
    }
};



const getTitleName = (page, props) => {
    const actualPage = getActualPage(page, props);
    if (actualPage === '4') {
        const rd = getRankingData(props.userId, props);
        if (rd) {
            return `${rd.firstName} ${rd.lastName}'s Picks`;
        }
    }else{
        return props.contest.name;
    }
};


const getRankingData = (id, props) => {
    return props.contest?.ranking?.filter(row => {
        if (row.id === id) {
            return true;
        }else{
            return false;
        }
    })[0];
};




const ContestPage: React.FC<ContestPageProps> = props  => {
    const { contest, page, changePage } = props;
    const actualTitle = getTitleName(page || (contest.started ? "1" : "0"), {
        contest,
        joinContest: props.joinContest,
        joinContestResults: props.joinContestResults,
        searchMusic: props.searchMusic,
        musicSearchResults: props.musicSearchResults,
        addPickToCollection: props.addPickToCollection,
        removePickFromCollection: props.removePickFromCollection,
        userData: props.userData,
        trackData: props.trackData,
        getTrackData: props.getTrackData,
        clearTrackData: props.clearTrackData,
        pickData: props.pickData,
        getPickData: props.getPickData,
        userId: props.userId,
        contestId: props.contestId,
    });
    if (contest && contest.id) {
        return (
            <IonContent>
                <Header menu={true}/>
                <ContestHeader title={actualTitle}/>
                <ContestSegmentTabs 
                    page={page} 
                    changePage={changePage} 
                    contest={contest}
                />
                {getRenderedPage(page || (contest.started ? "1" : "0"), {
                    contest,
                    joinContest: props.joinContest,
                    joinContestResults: props.joinContestResults,
                    searchMusic: props.searchMusic,
                    musicSearchResults: props.musicSearchResults,
                    addPickToCollection: props.addPickToCollection,
                    removePickFromCollection: props.removePickFromCollection,
                    userData: props.userData,
                    trackData: props.trackData,
                    getTrackData: props.getTrackData,
                    clearTrackData: props.clearTrackData,
                    pickData: props.pickData,
                    getPickData: props.getPickData,
                    userId: props.userId,
                    contestId: props.contestId,
                    getContestTracks: props.getContestTracks,
                    contestTracks: props.contestTracks,
                })}
                {!props.musicSearchResults.length ? <BottomNavTabs selected={1}/> : <></>}
            </IonContent>
        );
    }else{
        return (<IonLoading 
            isOpen={true}
            message={'Please wait...'}
        />)
    }
};

export default ContestPage;
