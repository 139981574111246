import React, { useState, useEffect } from 'react';
import {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonButton,
    IonItem,
    IonIcon,
    IonLabel,
    IonItemDivider,
    IonToast,
} from '@ionic/react';
import { pricetag, person, people, musicalNotes, play, pause } from 'ionicons/icons';
import { ContestType } from './../../actions/apiTypes';


interface JoinProps {
    contest: ContestType["contestData"],
    joinContest: Function,
    joinContestResults: {
        error: Boolean,
        errorMessage: string
    }
    userData: any
}




const JoinScreen: React.FC<JoinProps> = props => {
    
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const altMessage = message => {
        setShowError(true);
        setErrorMessage(message);
    };
    let fee = 0;
    
    return <IonCard>
        <IonCardHeader>
            <IonCardTitle>Contest Details</IonCardTitle>
        </IonCardHeader>
        <IonItem>
            <IonIcon icon={person} slot="end" color="primary" />
            <IonLabel>Created By: {props.contest.owner.firstName} {props.contest.owner.lastName}</IonLabel>
        </IonItem>
        <IonItem>
            <IonIcon icon={musicalNotes} slot="end" color="primary" />
            <IonLabel>Number of Picks: {props.contest.pick_count}</IonLabel>
        </IonItem>
        <IonItemDivider />
        <IonItem onTouchStart={() => {
            //CHECK USER WALLET
            if(props.contest.cost < props.userData?.wallet?.balance) {
                altMessage('You do not have enough funds to enter this contest, consider adding more funds to your wallet');
            }
        }}>
            <IonIcon icon={pricetag} slot="end" color={props.contest.cost < props.userData?.wallet?.balance ? "success" : "danger"} />
            <IonLabel>Entry Fee: {props.contest.cost}</IonLabel>
        </IonItem>
        <IonItem>
            <IonIcon icon={people} slot="end"/>
            <IonLabel>Current Players: {props.contest.ranking.length}</IonLabel>
        </IonItem>
        <IonItem onTouchStart={() => {
            if (props.contest.started) altMessage('You cannot join a contest that has already started');
        }}>
            <IonIcon icon={props.contest.started ? play : pause} slot="end" color={props.contest.started ? 'danger' : 'success'} />
            <IonLabel>Contest Started: {props.contest.started ? 'Yes' : 'No'}</IonLabel>
        </IonItem>
        <IonItemDivider />
        <IonItem>
            <IonButton 
                color="primary" 
                style={{width: '100%', height: '50px'}}
                onClick={() => {
                    props.joinContest(props.contest.id);
                }}
            >Join</IonButton>
        </IonItem>

        <IonToast
            color="danger"
            isOpen={showError}
            onDidDismiss={() => setShowError(false)}
            message={errorMessage}
            duration={10000}
        />
        
        {props.joinContestResults.error ? <IonToast
            color="danger"
            isOpen={true}
            message={props.joinContestResults.errorMessage}
            duration={5000}
        /> : ''}


    </IonCard>
};


export default JoinScreen;