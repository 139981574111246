import auth from '../auth';
import contests from '../contests';
import features from '../features';
import search from '../search';
import admin from '../admin';

const ducks = [
    auth,
    contests,
    search,
    features,
    admin,
];


//@ts-ignore;
const { reducers, sagas } = ducks.reduce(
  (acc, duck) => ({
//@ts-ignore;
    reducers: duck.reducers ? { ...acc.reducers, ...duck.reducers } : { ...acc.reducers },
//@ts-ignore;
sagas: duck.sagas ? { ...acc.sagas, ...duck.sagas } : { ...acc.sagas },
  }),
  {}
);

const createPreloadedState = req => ducks.reduce((acc, duck) => {
//@ts-ignore;
if (duck.getPreloadedState) {
//@ts-ignore;
return { ...acc, ...duck.getPreloadedState(req) };
  }

  return acc;
}, {});


export { reducers, sagas, createPreloadedState };
