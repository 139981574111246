import React, { useState, useEffect } from 'react';
import { IonAvatar, IonLabel, IonItem, IonButton } from '@ionic/react';

import ListView from './../Shared/ListView';
import { contest } from '../../ducks/contests/reducers';
import TrackModal from './TrackModal';

interface PickCollectionProps {
    contest: any
    remove?: Function
    trackData: any
    getTrackData?: Function
    clearTrackData?: Function
    picks?: any
    userId?: string
    userData?: any

    playlist?: any
}


const getPointsByRanking = (userId, trackId, ranking) => {
    let points;
    try{
        ranking.forEach(item => {
            if (item.id === userId) {
                item.points.tracksScore.forEach(p => {
                    if (p.trackId === trackId) points = p.score;
                })
            }
        });
    }catch(e) {
        
    }

    return points;
};


const PickCollection: React.FC<PickCollectionProps> = props => {
    const { contest: {pickCount, started, ranking }, trackData, getTrackData, clearTrackData } = props;
    const picks = props.picks || props.contest.picks;
    const userId = props.picks ? props.userId : props.userData?.id;
    const myContest = props.picks ? false : true;
    
    const [trackModalOpen, setTrackModalOpen] = useState(false)

    let content;

    useEffect(() => {
        if(trackData) {
            setTrackModalOpen(true);
        }
    })

    if (!props.playlist) {
        content = {
            headers: [
                { content: `Picks  (${picks.length}/${pickCount})`, size: 9 },
                { content: started ? 'Points' : '', size: 2 },
            ],
            rows: []
        };

        content.rows = picks.map(p => {
            return {
                cols: [
                <IonItem onClick={() => {
                    getTrackData(p.trackId);
                }}><IonAvatar slot="start">
                    <img src={p.artworkUrl60} />
                </IonAvatar>
                <IonLabel>
                    <h3>{p.artistName} - {p.trackName}</h3>
                </IonLabel></IonItem>,
                started ? getPointsByRanking(userId, p.trackId, ranking) :  myContest ? <IonButton 
                    style={{marginTop: '15px'}}
                    onClick={() => {
                        props.remove({
                            contestId: props.contest.id,
                            trackId: p.trackId
                        });
                    }}
                >Remove</IonButton> : <></>
                ]
            }
        });
    }else{
        content = {
            headers: [
                { content: `Playlist - ${props.playlist.length} Songs`, size: 12 }
            ],
            rows: []
        };
        content.rows = props.playlist.map(p => {
            return {
                cols: [
                <IonItem onClick={() => {
                    getTrackData(p.track_id);
                }}><IonAvatar slot="start">
                    <img src={p.artwork_url60} />
                </IonAvatar>
                <IonLabel>
                    <h2>{p.artist_name} - {p.track_name}</h2>
                    <p>{p.first_name} {p.last_name}</p>
                </IonLabel></IonItem>,
                ]
            }
        });
    }
    return <>
        <ListView 
            // @ts-ignore
            content={content}
        /><TrackModal 
            open={trackModalOpen}
            trackData={trackData}
            close={() => {
                setTrackModalOpen(false);
                clearTrackData();
            }}/>
    </>;
};


export default PickCollection;

