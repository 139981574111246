import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { IonContent, IonText, IonButton, IonModal, IonHeader, IonItem, IonLabel, IonInput } from '@ionic/react';

import ListView, { ListViewProps } from './../Shared/ListView';
import LeaderboardUserComponent from './LeaderboardUserComponent';
import { ContestType } from './../../actions/apiTypes';
import TrackModal from './TrackModal'
import { removeFromContest } from '../../actions/api';

const StyledWindow = styled.div`
    overflow-y: scroll;
    -webkit-overflow-scrolling: auto;
    height: calc(100vh - 209px);
    
`;

interface LeaderboardProps {
    contest: ContestType["contestData"]
    userData: any
    joinContest: Function
    removeFromContest: Function
}


const buildHeaders = contest => {
    let headers = []
    if (contest.started) {
        headers.push({ content: 'Rankings', size: '6'});
        headers.push({ content: 'Overall', size: '4'});
    }else{
        headers.push({ content: 'Users', size: '12' });
    }
    return headers;
}

const buildRows = (contest, userData, removeFromContest) => {
    const admin = userData.admin;
    return contest.ranking.map((user, index) => {
        let row = { cols: []};
        row.cols.push(<LeaderboardUserComponent 
            firstName={user.firstName} 
            lastName={user.lastName}
            id={user.id}
            rank={index+1}
            me={userData?.id === user?.id}
            admin={admin}
            contest={contest}
            removeFromContest={removeFromContest}
        />);

        if (contest.started) {
            row.cols.push(<p>{user.points.totalScore}</p>);
        }
        //@ts-ignore
        row.onClick = () => {
            window.location.href = `/contest/${contest.id}?p=4&contestId=${contest.id}&userId=${user.id}`;    
        };

        return row;
    });
}


const Leaderboard: React.FC<LeaderboardProps> = props  => {
    const { admin } = props.userData;

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {

    }, []);
    const data: ListViewProps["content"] = {
        headers: buildHeaders(props.contest),
        rows: buildRows(props.contest, props.userData, props.removeFromContest),
    };

    // @ts-ignore
  return (<StyledWindow>
      <ListView content={data}/>
      {admin ? <IonItem><IonButton onClick={() => setShowModal(true)}>Add User</IonButton></IonItem> : <></>}
      <AddUserModal open={showModal} close={() => setShowModal(false)} addUser={u => props.joinContest(props.contest.id, u)}/>
    </StyledWindow>);
};


const AddUserModal = props => {
    const { open, close, addUser} = props;
    const [email, setEmail] = useState('');
    return <IonModal 
            isOpen={open} 
            animated={true} 
            swipeToClose={true} 
            showBackdrop={false} 
            onDidDismiss={() => close()}
            style={{maxHeight: '100px'}}
        >
        <IonItem>
            <b>Add User</b>
            <IonLabel position="floating">User Email</IonLabel>
            <IonInput value={email} onIonChange={e => setEmail(e.detail.value!)} />
            <IonButton slot="end" onClick={() => {
                addUser(email);
                close();
            }}>Save</IonButton>
        </IonItem>
        <IonButton slot="end" onClick={() => close()}>CLOSE</IonButton>
    </IonModal>;
}


export default Leaderboard;
