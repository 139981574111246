import axios from 'axios';
import Cookie from 'js-cookie';
import { encode } from 'punycode';

let savedToken;



const updateToken = token => {
    if (token) {
        Cookie.set('authorization', token, { expires: 30*12 });
    }

    return Cookie.get('authorization');
};


const killToken = () => {
    Cookie.remove("authorization");
}


const  apiRequest = axios.create({
    baseURL: `${GLOBAL_CONFIG.BASE_URL}/api/v1/`,
    timeout: 5000,
    headers: {}
});

apiRequest.interceptors.request.use((config) => {
    // Do something before request is sent
    config.headers['authorization'] = updateToken(null);
    return config;
}, (error) => {
    // Do something with request error
    return Promise.reject(error);
});


apiRequest.interceptors.response.use(response => {
    if (response.headers.verifiedtoken) {
        console.log('setting new token', response.headers.verifiedtoken)
        updateToken(response.headers.verifiedtoken);
    }
    return response;
}, err => {
    console.log('err-response', err.response);
    if (err.response.status === 401) {
        window.location.href = "/login";
    }
    return Promise.reject(err);
});


export const getMe = async token => {
    savedToken = updateToken(token);
    const req = await apiRequest.get('me');
    console.log('me-req', req);
    return req;
};

export const getContests = async token => {
    savedToken = updateToken(token);
    return await apiRequest.get('contests');
}
export const getContestHistory = async token => {
    savedToken = updateToken(token);
    return await apiRequest.get('contests/history');
}

export const getFeatured = async token => {
    savedToken = updateToken(token);
    return await apiRequest.get('featured');
}
export const adminSetFeatured = async saveObj => {
    return await apiRequest.put(`featured/${saveObj.id}`, saveObj);
}

export const signup = async formData => {

    return await apiRequest.post('signup', formData);
};

export const createContest = async formData => {
    return await apiRequest.post('contest', formData);    
};

export const getContest = async formData => {
    // for (var i=0; i<50; i++) {
    //     axios.get('https://itunes.apple.com/lookup?id=90925' + i);
    // }
    // return await axios.get('https://itunes.apple.com/lookup?id=909253');
    return await apiRequest.get(`contest/${formData}`);
};


export const searchContest = async value => {
    return await apiRequest.get(`contest/search?q=${encodeURI(value)}`);
};


export const joinContest = async (id, email) => {
    if (email) {
        return await apiRequest.post(`contest/${id}/join`, { email });
    }else{
        return await apiRequest.post(`contest/${id}/join`);
    }
}

export const removeFromContest = async (contestId, userId) => {
    return await apiRequest.post(`contest/${contestId}/remove`, {user_id: userId});
}

export const searchMusic = async term => {
    return await axios.get(`https://itunes.apple.com/search?term=${encodeURI(term)}`);
}


export const addPickToCollection = async pick => {
    return await apiRequest.put(`/contest/${pick.contestId}/collection`, pick);
};

export const removePickFromCollection = async pick => {
    return await apiRequest.delete(`/contest/${pick.contestId}/collection/${pick.trackId}`);
}

export const logout = () => {
    killToken();
    return true;
}


export const adminGetTracksForData = async () => {
    return await apiRequest.get(`/admin/tracks`);
}

export const adminPullTrackData = async ids => {
    return await apiRequest.post(`/admin/tracks`, {ids}, {
        timeout: 30000
    });
}

export const adminSaveTrackData = async trackData => {
    return await apiRequest.put(`/admin/tracks`, {trackData});
}

export const adminGetTrackSource = async () => {
    return await apiRequest.get(`/admin/sources`);
}

export const adminGetYoutubeSource = async name => {
    return await apiRequest.get(`/admin/sources/videos?s=${encodeURI(name)}`);
}

export const adminSetYoutubeSource = async ({ id, youtube_id }) => {
    return await apiRequest.post(`/admin/sources/set/${id}`, { youtube_id },{
        timeout: 30000
    });
};

export const getTrackData = async id => {
    return await apiRequest.get(`/tracks/${encodeURI(id)}`);
};

export const getUserPicks = async ({contestId, userId}) => {
    return await apiRequest.get(`/contest/picks?contestId=${contestId}&userId=${userId}`);
};

export const getContestTracks = async id => {
    return await apiRequest.get(`/contest/${encodeURI(id)}/picks`);
};