import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { 
    IonItem,
    IonAvatar,
    IonLabel,
    IonButton
} from '@ionic/react';

const StyledIonItem = styled(IonItem)`
    display: block;
`;

const StyledReleaseDate = styled.span`
    font-size: .8em;
    color: #1d1d1d;
`;



const SearchResultItem = props  => {
    const { contestId } = useParams();
    const {item, add, setSearchVal} = props;
  useEffect(() => {
  })
  const releaseDate = new Date(item.releaseDate);
  return (
    <StyledIonItem >
        <IonAvatar slot="start">
            <img src={item.artworkUrl30} />
        </IonAvatar>
        <IonLabel>
            <h3>{item.artistName} - {item.trackName}</h3>
            <StyledReleaseDate>Released: {releaseDate.toLocaleDateString()}</StyledReleaseDate>
        </IonLabel>

        {props.showAdd ? <IonButton onClick={() => {
            setSearchVal("");
            add({...item, contestId});
        }}>{props.addLabel || "Add"}</IonButton> : <></>}
    </StyledIonItem>
  )
};

export default SearchResultItem;
