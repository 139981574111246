import React, {useEffect} from 'react';
import Styled from 'styled-components';

import PickCollection from './PickCollection';

const StyledWindow = Styled.div`
    overflow-y: scroll;
    -webkit-overflow-scrolling: auto;
    height: calc(100vh - 235px);
    
`;

interface PlaylistPageProps {
    getContestTracks: Function
    contestTracks: any
    contestId: string
    trackData: any
    getTrackData: Function
    contest: any
    clearTrackData: Function
}


const PlaylistPage: React.FC<PlaylistPageProps> = props => {
    useEffect(() => {
        if (!props.contestTracks) {
            props.getContestTracks(props.contestId);
        }
    }, []);
    if (props.contestTracks) {
        return <StyledWindow><PickCollection 
            trackData={props.trackData}
            getTrackData={props.getTrackData}
            contest={props.contest}
            playlist={props.contestTracks}
            clearTrackData={props.clearTrackData}
        /></StyledWindow>
    }else{
        return <></>;
    }
};



export default PlaylistPage;