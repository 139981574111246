import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import LoginPage from '../components/SignUpIn/LoginPage';
import { loginComplete  } from './../actions/auth';
import { logout } from './../actions/api';
import * as Sentry from '@sentry/react'; 

const LoginContainer: React.FC = (state, props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    
    const LoginComplete = userData => {
      console.log('loginData', userData.authResult.credential);
      //@ts-ignore
      Sentry.captureException(JSON.stringify(userData.authResult.credential));
      dispatch(loginComplete(userData, history))
    };


  return (<LoginPage LoginComplete={LoginComplete}/>)
};

export default connect((state, props) => {
  if (props.location?.pathname === '/logout') {
    logout();
    props.history.push('/login');
  }else{
    return props;
  }
})(LoginContainer);
